<template>
  <ion-page>
    <ion-content id="main-content" :fullscreen="true">
      <div class="spinner-container">
        <ion-spinner color="primary" name="lines"></ion-spinner>
      </div>
    </ion-content>

  </ion-page>
</template>

<script>
  import isElectron from 'is-electron';
  import { mapActions, mapMutations } from 'vuex';
  import { IonContent, IonPage } from '@ionic/vue';

  export default {
    name: 'Logout',

    components: {
      IonContent,
      IonPage,
    },

    mounted() {
      this.logout();
      this.clearSelectedLocation();
      if(isElectron()) {
        this.$router.push({name: 'Login'});
      } else {
        window.location = '/auth/login';
      }
    },

    methods: {
      ...mapActions({
        logout: 'auth/logout',
      }),

      ...mapMutations({
        setLocation: 'locations/SET_CURRENT',
      }),

      clearSelectedLocation() {
        this.setLocation(null);
        localStorage.removeItem('tithely:location');
      },
    }
  };
</script>

<style scoped lang="scss">
  ion-content {

    .spinner-container {
    height: 100vh;
    width: 100%;
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  }
</style>
